<template>
    <!-- FAQ Area Start -->
    <section id="faq" class="faq-area">
        <div class="container">

            <div class="row">
                <div class="col-12">
                    <div class="section-title-area text-center">
                          <SectionHeading2 :title="title" :titleInner="titleInner" /> 
                          <br>
                          <SectionSubHeading :subTitle="subTitle" />  
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- FAQ List Left Side Start -->
                <!-- <div class="col-12 col-sm-12"> -->
                    <div class="faq-wrapper">
                        <div class="faq-all-box">
                            <div class="panel-group" id="accordion6" role="tablist">

                                <div class="col-6 col-sm-12 col-md-6">

                                    <!-- Single Features Box-->
                                    <b-card no-body class="panel">
                                        <b-card-header header-tag="header" class="panel-heading" role="tab">
                                            <h4 class="panel-title">
                                                <a block>Debt Management Strategy</a>
                                            </h4>
                                        </b-card-header>
                                            <b-card-body>
                                                <b-card-text>
                                                    We understand the complexities of managing debt. Our expert team provides tailored strategies to help you efficiently manage and reduce your debt burden, ensuring financial stability.
                                                </b-card-text>
                                            </b-card-body>
                                    </b-card>
                                    
                                    <!-- Single Features Box-->

                                    <!-- Single Features Box-->
                                    <!-- <div class="col-12 col-sm-12 col-md-6"> -->
                                    <b-card no-body class="panel">
                                        <b-card-header header-tag="header" class="panel-heading" role="tab">
                                            <h4 class="panel-title">
                                                <a>Financial Modeling and Budgeting Services</a>
                                            </h4>
                                        </b-card-header>
                                            <b-card-body>
                                                <b-card-text>
                                                    Planning is key to financial success. Tharwa Consultants specializes in developing robust financial models and budgets that provide a clear roadmap for achieving your financial goals.
                                                </b-card-text>
                                            </b-card-body>
                                    </b-card>
                                    <!-- Single Features Box-->

                                </div>
                                
                                <div class="col-6 col-sm-12 col-md-6">

                                    <!-- Single Features Box-->
                                    <b-card no-body class="panel">
                                        <b-card-header header-tag="header" class="panel-heading" role="tab">
                                            <h4 class="panel-title">
                                                <a block>Cashflow Mismatch Solutions</a>
                                            </h4>
                                        </b-card-header>
                                            <b-card-body>
                                                <b-card-text>
                                                    Managing cashflow discrepancies is vital for business sustainability. We offer innovative solutions to align your cash inflows and outflows, ensuring smooth financial operations.
                                                </b-card-text>
                                            </b-card-body>
                                    </b-card>
                                    <!-- Single Features Box-->

                                    <!-- Single Features Box-->
                                    <b-card no-body class="panel">
                                        <b-card-header header-tag="header" class="panel-heading" role="tab">
                                            <h4 class="panel-title">
                                                <a block>Loan Requests and Lending Advice</a>
                                            </h4>
                                        </b-card-header>
                                            <b-card-body>
                                                <b-card-text>
                                                     Need financing for your business? Our experienced consultants guide you through the loan application process, offering valuable lending advice to secure the best terms and rates.
                                                </b-card-text>
                                            </b-card-body>
                                    </b-card>
                                    <!-- Single Features Box-->
                                </div> 

                            </div>
                        </div>
                    </div>
                <!-- </div> -->
                <!-- FAQ List Left Side End -->
                
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="section-title-area text-center">
                        <br>
                        <br>
                          <SectionSubHeading1 :subTitle="bottomPart" />  
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- FAQ Area End -->
</template>

<script>

import SectionHeading2 from '@/components/default/SectionHeading2'
import SectionSubHeading from '@/components/default/SectionSubHeading'
import SectionSubHeading1 from '@/components/default/SectionSubHeading'

export default {
  name: 'PopularQuestions',

  components: {
    SectionHeading2,
    SectionSubHeading,
    SectionSubHeading1
  },

  data() {
    return {
      title: 'Services & Solutions',
      titleInner: 'Our',
      subTitle: 'We offer a comprehensive suite of financial solutions designed to empower individuals and businesses in their financial endeavors. Our services are tailored to address a wide spectrum of financial needs, including',
      bottomPart: 'Our commitment to your financial well-being drives us to deliver strategic, customized solutions that transform financial challenges into opportunities for growth and prosperity'
      };
    },
}
</script>

<style scoped>
.faq-area .card-header.panel-heading {
	padding: 0;
}
.faq-area #accordion6 .panel-title a {
	min-height: auto;
}
.faq-area #accordion6 .card-body {
    border-bottom: 1px solid transparent;
	padding-left: 0;
}
.faq-area .card-header {
	border-bottom: 1px solid transparent;
}
</style>