<template>
  <div class="">
    <!-- Get Free Download Area Start -->
    <section id="download" class="get-free-download-area bg-primary">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="get-free-download-wrapper">

                        <div class="section-title-area">
                          
                            <SectionHeading :title="title" />
                        </div>

                        <div class="features-1-all-box features-2-all-box">

                            <div class="panel-group" id="accordion6" role="tablist">

                                <!-- Single Features Box-->
                                <b-card no-body class="panel">
                                    <b-card-header header-tag="header" class="panel-heading" role="tab">
                                        <h4 class="panel-title">
                                            <a block v-b-toggle.accordion-1><i class="fa fa-brain"></i> Expertise</a>
                                        </h4>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-card-text>
                                                Our team comprises highly skilled financial experts with a profound understanding of the Omani market. With their experience and knowledge, we offer insightful solutions tailored to your specific needs.
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <!-- Single Features Box-->

                                <!-- Single Features Box-->
                                <b-card no-body class="panel">
                                    <b-card-header header-tag="header" class="panel-heading" role="tab">
                                        <h4 class="panel-title">
                                            <a block v-b-toggle.accordion-2><i class="flaticon-social-media"></i> Client-Centric Approach</a>
                                        </h4>
                                    </b-card-header>
                                    <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-card-text>
                                                 We prioritize our clients' interests above all else. Your financial well-being is our primary concern, and we work tirelessly to provide customized solutions that align with your objectives.
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <!-- Single Features Box-->

                                <!-- Single Features Box-->
                                <b-card no-body class="panel">
                                    <b-card-header header-tag="header" class="panel-heading" role="tab">
                                        <h4 class="panel-title">
                                            <a block v-b-toggle.accordion-3><i class="flaticon-shield"></i> Ethical Practices</a>
                                        </h4>
                                    </b-card-header>
                                    <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-card-text>
                                                 Integrity and ethics are the cornerstones of our business. We uphold the highest ethical standards in every aspect of our consultancy services, ensuring transparency and trust.
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <b-card no-body class="panel">
                                    <b-card-header header-tag="header" class="panel-heading" role="tab">
                                        <h4 class="panel-title">
                                            <a block v-b-toggle.accordion-4><i class="far fa-lightbulb"></i>Innovation</a>
                                        </h4>
                                    </b-card-header>
                                    <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-card-text>
                                                 We stay at the forefront of financial trends and technology to provide cutting-edge solutions. Our commitment to innovation enables us to adapt to the ever-changing financial landscape
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                 <b-card no-body class="panel">
                                    <b-card-header header-tag="header" class="panel-heading" role="tab">
                                        <h4 class="panel-title">
                                            <a block v-b-toggle.accordion-5><i class="flaticon-location-pin"></i> Local Knowledge, Global Perspective</a>
                                        </h4>
                                    </b-card-header>
                                    <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <b-card-text>
                                                 Based in Oman, we have a deep understanding of the local market dynamics. However, our global perspective allows us to offer insights and strategies that transcend borders.
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <!-- Single Features Box-->
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Get Free Download Area End -->
  </div>
</template>

<script>
import SectionHeading from '@/components/default/SectionHeading'

export default {
  name: 'DownloadApp',
  components: {
    SectionHeading,
  },
  data(){
    return{
      title: 'Why Choose US',
      mockUpImg1: require('../../assets/img/get-free-download1.png'),
      altText3: 'Mockup',
      mockUpImg2: require('../../assets/img/get-free-download2.png'),
      altText4: 'Mockup'
    }
  }
}
</script>
