<template>
  <h2 class="section-heading">{{ title }}</h2>
</template>

<script>
export default {
  name: 'SectionHeading',
  props: {
    title: String
  }
}
</script>