var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('section',{staticClass:"contact-area",attrs:{"id":"contact"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-8"},[_c('div',{staticClass:"contact-form-wrapper"},[_c('form',{attrs:{"id":"contact-form"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"d-flex message-button-wrap"},[_c('div',{staticClass:"subm-btn theme-button"},[_c('input',{staticClass:"btn contact-btn",attrs:{"type":"submit","value":"Send Message"},on:{"click":_vm.sendEmail}})]),_c('div',{staticClass:"sending-gif",staticStyle:{"display":"none"}},[_c('b-img',{attrs:{"src":require('../../assets/img/loading.gif'),"img-alt":"sending"}})],1)]),_vm._m(3)]),_vm._m(4)])]),_vm._m(5)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Full Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"name","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"email","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-area form-group"},[_c('label',{attrs:{"for":"message"}},[_vm._v("Write Message")]),_c('textarea',{staticClass:"form-control",attrs:{"id":"message","rows":"3","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br'),_c('p',{staticClass:"section-heading"},[_vm._v("Disclaimer: Tharwa Consultants LLC is a registered financial consultancy firm in Oman. All services are provided in accordance with the relevant laws and regulations.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"success-msg alert alert-success",staticStyle:{"display":"none"}},[_c('strong',[_vm._v("Success!")]),_vm._v(" Email Send Successfully. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-12 col-md-4"},[_c('div',{staticClass:"section-title-area"},[_c('h2',{staticClass:"section-heading"},[_vm._v("Get in touch")]),_c('p',{staticClass:"section-heading"},[_vm._v("Our dedicated team is eager to assist you in achieving your financial aspirations.")])]),_c('div',{staticClass:"contact-address"},[_c('ul',[_c('li',[_c('span',[_c('i',{staticClass:"flaticon-e-mail-envelope"})]),_vm._v(" tharwaconsultants@gmail.com ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"google-map"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234021.95947786665!2d58.259706088107265!3d23.58305251558349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!3m2!1sen!2som!4v1695458631757!5m2!1sen!2som","width":"600","height":"450","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])
}]

export { render, staticRenderFns }