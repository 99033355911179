<template>
  <div class="home home-1" id="list-of-sections">
    <Header1 />
    <Banner1 />
    <Home5BlogSection/>
    <PopularQuestions />
    <DownloadApp /> 
    <!-- <MeetTeam /> -->
    <HomeContact1 />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header1 from '@/components/Header1.vue'
import Banner1 from '@/components/home1/Banner1.vue'
import DownloadApp from '@/components/home1/DownloadApp.vue'
import HomeContact1 from '@/components/home1/HomeContact1.vue'
import PopularQuestions from '@/components/home4/PopularQuestions.vue'
import Home5BlogSection from '@/components/home5/Home5BlogSection.vue'
import Footer from '@/components/Footer.vue'
// import MeetTeam from '@/components/home1/MeetTeam.vue'

export default {
  name: 'Home1',
  components: {
    Header1,
    Banner1,
    PopularQuestions,
    Home5BlogSection,
    DownloadApp,
    HomeContact1,
    // MeetTeam,
    Footer,
  }
}
</script>
