<template>
  <div class="">
    <!-- Header Start -->
    <header
      id="home"
      class="home-banner-area home-banner-area-2 position-relative"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!--Hero Area Wrapper-->
            <div class="hero-area-wrapper position-relative">
              <div class="hero-container">
                <div class="hero-area-content">
                  <h1>{{ heroTitle }}</h1>
                  <p>{{ heroSubTitle }}</p>
                  <p>{{ heroSubTitleAction }}</p>
                </div>
                <div class="hero-right-wrapper">
                  <div class="hero-right-bg wow fadeInRight">
                    <img
                      src="https://media.giphy.com/media/JrXas5ecb4FkwbFpIE/giphy.gif"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--Hero Area Wrapper-->
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
  </div>
</template>

<script>
export default {
  name: "Banner1",

  data() {
    return {
      // particleImage: require('../../assets/img/hero-bg-effect.png'),
      heroRightBg1: require("../../assets/img/home-2-img/hero-mobile-bg.png"),
      altText2: "image",
      altText: "particle",
      heroTitle: "Unlock Financial Success for Your Business",
      heroSubTitle: "Tharwa Consultants - Your Trusted Financial Partner.",
      heroSubTitleAction: "Let's Get Started",
    };
  },
};
</script>

<style scoped>
/* Add a media query for smaller screens */
@media (max-width: 768px) {
  .hero-container {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .hero-area-content{
    text-align: center; /* Center-align text on small screens */
  }
  .hero-right-bg{
    margin-top: 100%;
  }
  .hero-right-wrapper{
    width: 50%;
    display: block;
  margin: 0 auto; /* This centers the image horizontally */
  }
}

@media only screen and (min-width: 800px) and (max-width: 1199px){
  .hero-container {
    display: flex;
    /* flex-direction: column-reverse !important; */
  }
  .hero-area-content{
    text-align: center; /* Center-align text on small screens */
  }
  .hero-right-bg{
    margin-top: 100%;
    margin-left: 10%;
  }
  .hero-right-bg img{
    width: 100% !important;
  }
  .hero-right-wrapper{
    width: 50% !important;
    display: block;
    margin: 0 auto; /* This centers the image horizontally */
  }
}
</style>