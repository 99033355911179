<template>
  <p class="section-subheading">{{ subTitle }}</p>
</template>

<script>
export default {
  name: 'SectionSubHeading',
  props: {
    subTitle: String
  }
}
</script>