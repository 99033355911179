<template>
  <div class="">
    <!-- Contact Area Start -->
    <section id="contact" class="contact-area">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-8">
            <!--contact-left-text-->
            <div class="contact-form-wrapper">
              <form id="contact-form">
                <div class="form-group">
                  <label for="name">Full Name</label>
                  <input type="text" class="form-control" id="name" required />
                </div>
                <!--form-group-->
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    required
                  />
                </div>
                <!--form-group-->
                <div class="message-area form-group">
                  <label for="message">Write Message</label>
                  <textarea
                    class="form-control"
                    id="message"
                    rows="3"
                    required
                  ></textarea>
                </div>
                <!--message-area-->
                <div class="d-flex message-button-wrap">
                  <div class="subm-btn theme-button">
                    <input
                      type="submit"
                      class="btn contact-btn"
                      value="Send Message"
                      @click="sendEmail"
                    />
                  </div>
                  <div class="sending-gif" style="display: none">
                    <b-img
                      :src="require('../../assets/img/loading.gif')"
                      img-alt="sending"
                    ></b-img>
                  </div>
                </div>
                <div>
                  <br>
                  <p class="section-heading">Disclaimer: Tharwa Consultants LLC is a registered financial consultancy firm in Oman. All services are provided in accordance with the relevant laws and regulations.</p>
                </div>
                <!--d-flex-->
              </form>
              <!--form-->
              <div
                class="success-msg alert alert-success"
                style="display: none"
              >
                <strong>Success!</strong> Email Send Successfully.
              </div>
            </div>
            <!--contact-form-->
          </div>
          <!--col-md-8-->

          <div class="col-12 col-sm-12 col-md-4">
            <div class="section-title-area">
              <h2 class="section-heading">Get in touch</h2>
              <p class="section-heading">Our dedicated team is eager to assist you in achieving your financial aspirations.</p>
            </div>
            <div class="contact-address">
              <ul>
                <li>
                  <span><i class="flaticon-e-mail-envelope"></i></span>
                  tharwaconsultants@gmail.com
                </li>
                <!-- <li><span><i class="flaticon-call"></i></span>+968 9120 1123</li> -->
                <!-- <li>
                  <span><i class="flaticon-e-mail-envelope"></i></span
                  >Hussain@Tharwaconsultants.com
                </li> -->
              </ul>
            </div>
            <!--contact-address-->
          </div>
          <!--col-md-4-->
        </div>
        <!--row-->
      </div>
      <!--container-->
      <div class="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234021.95947786665!2d58.259706088107265!3d23.58305251558349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!3m2!1sen!2som!4v1695458631757!5m2!1sen!2som"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
    <!-- Contact Area End -->
  </div>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";

export default {
  name: "HomeContact1",
   data: () => ({
        email: "",
        message: "",
        name: "",

    }),
    methods: {
      sendEmail(){
        console.log('function');
         loadScript("https://smtpjs.com/v3/smtp.js")
        .then(() => {                                        
            window.Email && window.Email.send({
        SecureToken: "5d731188-4e1d-48d6-aea8-24c8deafa84d",
          To: "tharwaconsultants@gmail.com",
          From: "noreply@tharwaconsultants.com",
          name: this.name,
          Subject: "New contact from",
          Body: "Name: " + document.getElementById("name").value
          + "<br> Email: " + document.getElementById("email").value
          + "<br> Message: " + document.getElementById("message").value,
            }).then(
                (msg) => alert( msg) //"Message sent Successfully " +
            );
        })
        .catch(() => {
        // Failed to fetch script
        console.log('functionasd');
        });
      }
    }
};
</script>